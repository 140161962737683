
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import AuthService from '../services/auth-service';
import { State, Action, Getter } from 'vuex-class';
import MasterLayout from '@/components/MasterLayout.vue';
import { Address, Email } from '@/store/models/AddressModel';
import { ConstValues } from '@/ConstValues';
import { CustomerService } from '@/services/customer-service';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { DisableFormAutofill } from '@/helpers/WebHelper';

@Component({
  components: { MasterLayout }
})
export default class UserRegisterComponent extends Vue {
  $refs!: {
    registrationForm: HTMLFormElement;
  };
  @Action('login', { namespace: 'profile' })
  login: any;
  firstName: string = '';
  lastName: string = '';
  password: string = '';
  confirmPassword: string = '';
  email: string = '';
  userName: string = '';
  errors: string[] = [];
  emailRules: any[] = [
    (v: any) => !!v || 'E-mail is required',
    (v: any) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
  ];
  valid: boolean = false;
  companyName: string = '';
  isCreatingAccount: boolean = false;
  addressToEdit: Address = new Address();
  didCreateAccount: boolean = false;
  states: { id: string; name: string }[] = [];
  countries: any[] = [];
  get passwordConfirmationRule() {
    return this.password === this.confirmPassword || 'Password must match';
  }
  goToLogin(): void {
    this.$router.push('/login');
  }
  async saveUser() {
    this.isCreatingAccount = true;
    const formData: any = new FormData();
    formData.set('userName', this.userName);
    formData.set('FirstName', this.firstName);
    formData.set('LastName', this.lastName);
    formData.set('Password', this.password);
    formData.set('CompanyName', this.companyName);
    formData.set('Email', this.userName);
    try {
      await AuthService.createUser(formData);
      this.$gtag.event('new_user_sign_up', {
        event_category: 'Custom',
        event_label: 'New Signup',
        value: 500
      });
      await this.AddAddress();
      this.didCreateAccount = true;
      window.scrollTo(0, 0);
    } catch (error) {
      SetSnackBar('Something went wrong while signing you up');
    }
    this.isCreatingAccount = false;
  }
  goToAmazonTransparency() {
    window.location.replace('https://transparency.just1label.com');
  }

  async GetStates() {
    const response = await CustomerService.GetStates();
    this.states = Object.keys(response.data).map(key => ({
      id: key,
      name: response.data[key]
    }));
  }
  async GetCountries() {
    const response = await CustomerService.GetCountries();
    this.countries = response.data;
  }

  async AddAddress() {
    this.addressToEdit.Email = new Email({ EmailAddress: this.userName });
    let model = ConversionHelper.convertAddressToAddressModel(
      this.addressToEdit,
      true,
      true,
      true
    );
    await CustomerService.AddNewBillingAddress(model);
  }

  get isRegistrationValid() {
    if (this.$refs.registrationForm) {
      this.valid = this.$refs.registrationForm.validate();
    }

    return this.valid && this.userName && this.companyName && this.password;
  }
  get ConstValues() {
    return ConstValues;
  }

  mounted() {
    this.GetStates();
    this.GetCountries();
    // DisableFormAutofill('registrationForm');
  }
}
